/* 1.Rithika pasupuleti 1002175585
2.Saikiranreddy Peddavootla 1002175284
3.Likitha Sathishkumar 1002159291
4.Pathak Viraj Hemangkumar 1002195291
5.Jyothsna Pasupuleti 1002172750
 */

/* Home Container */
.home-container {
  /* max-width: 1200px; */
  margin: auto;
  padding: 20px;
}

/* Hero Section */
.hero {
  display: flex;
  align-items: center;
  background-color: #007bff;
  color: white;
  padding: 40px 20px;
  border-radius: 8px;
  margin-bottom: 40px;
}

.hero-content {
  /* flex: 1; */
  padding: 20px;
}
.hero-content h1
{
  font-size: 40px;
}
.footer
{
  background-color: black;
  color: white;
  text-align: center;
}
.hero-image img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  width: 100%;
}

/* Button Styles */
.cta-button {
  background-color: #ffdd57;
  color: #007bff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
}

.cta-button:hover {
  background-color: #ffcc00;
}

/* About Section */
.about {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}

.about-image img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

.about-content {
  flex: 1;
  padding: 20px;
}

/* Featured Theses Section */
.featured-theses {
  margin-top: 40px;
}

.theses-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.thesis-item {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.thesis-link {
  display: inline-block;
  margin-top: 10px;
  color: #007bff;
  text-decoration: none;
}

.thesis-link:hover {
  text-decoration: underline;
}
/* src/App.css */
.container {
  padding: 20px;
}

h1, h2, h3 {
  color: #333;
}

.mt-4 {
  margin-top: 1.5rem;
}

.list-group-item {
  background-color: #fff;
  border: 1px solid #ddd;
}
/* src/components/AboutUs.css */
.container {
  max-width: 800px;
  margin: auto;
  padding: 20px;
}

.hero {
  background-image: url('../public/1.jpg'); /* Add your hero image here */
  background-size: cover;
  background-position: center;
  padding: 80px 20px; /* Space for text */
  color: white; /* Text color */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6); /* Text shadow for better readability */
}

.hero-content {
  max-width: 600px;
  margin: auto;
}

.hero-content h1 {
  color: #dedbdb;
  margin-bottom: 20px;
}

h2 {
  color: #007bff;
  margin-bottom: 10px;
}

.card {
  border: none; /* Remove default card border */
  border-radius: 8px; /* Rounded corners */
}

.card-img-top {
  border-radius: 8px 8px 0 0; /* Round the top corners of the image */
}

.card-body {
  padding: 15px; /* Add padding for the card body */
}

.card-title {
  font-size: 1.25rem; /* Font size for card title */
}

.card-text {
  font-size: 0.9rem; /* Smaller font size for card text */
  color: #666; /* Text color */
}

.mb-4 {
  margin-bottom: 1.5rem; /* Space between sections */
}
/* src/components/Contact.css */
.container {
  max-width: 800px;
  margin: auto;
  padding: 20px;
}

.contact-form {
  margin-bottom: 2rem;
}

.form-group {
  margin-bottom: 1rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-group textarea {
  resize: vertical; /* Allows vertical resizing of textarea */
}

.btn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
}

.btn:hover {
  background-color: #0056b3;
}

.faq {
  margin-bottom: 2rem;
}

.faq-item {
  margin-bottom: 1rem;
}

.contact-info {
  background-color: #f8f9fa;
  padding: 10px;
  border-radius: 5px;
}

.row
{
  display: flex;
}
.col-6
{
  width: 50%;
}
/* 1.Rithika pasupuleti 1002175585
2.Saikiranreddy Peddavootla 1002175284
3.Likitha Sathishkumar 1002159291
4.Pathak Viraj Hemangkumar 1002195291
5.Jyothsna Pasupuleti 1002172750
 */
* {
    box-sizing: border-box;
  }
  
  @import url('https://fonts.googleapis.com/css?family=Poppins');  
  body {
    margin: 0;
    font-family: 'Poppins', sans-serif;
    overflow-x: hidden; 
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #4CAF50;
    padding: 20px 20px;
    color: white;
    position: relative;
  }
  
  .logo h1 {
    margin: 0;
    font-size: 1.5rem;
    color: white;
  }
  
  .nav {
    position: relative;
  }
  
  .nav ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex; /* Default layout for larger screens */
    overflow: hidden; /* Prevent overflow on larger screens */
  }
  
  .nav ul li {
    margin-left: 20px;
  }
  
  .nav ul li a {
    color: white;
    text-decoration: none;
  }
  
  .hamburger {
    display: none; /* Hidden by default for larger screens */
    flex-direction: column;
    cursor: pointer;
  }
  
  .bar {
    height: 4px;
    width: 25px;
    background-color: white;
    margin: 3px 0;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .nav ul {
      display: none; /* Hide by default on mobile */
      flex-direction: column; /* Stack vertically */
      position: absolute; /* Allow it to float */
      top: 25px;
        left: -120px;
      background-color: #4CAF50; /* Same color as header */
      width: 200px; /* Full width */
      z-index: 10; /* Ensure it overlays other content */
    }
  
    .nav ul.active {
      display: flex; /* Show menu when 'active' class is applied */
    }
  
    .hamburger {
      display: flex; /* Show hamburger icon on smaller screens */
    }
  }
  
/* Search.css */

.search-container {
    max-width: 1200px;
    margin: auto;
    padding: 20px;
}

.search-filters {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
}

.search-filters input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    flex: 1;
    min-width: 200px;
}

.search-button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
}

.search-button:hover {
    background-color: #4CAF50;
}

.results-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.results-table th, .results-table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.results-table th {
    background-color: #4CAF50;
    color: white;
}

.pagination {
    display: flex;
    gap: 5px;
}

.pagination button {
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #f4f4f4;
    cursor: pointer;
}

.pagination button:hover {
    background-color: #e2e2e2;
}

.pagination .active {
    background-color: #4CAF50;
    color: white;
}
